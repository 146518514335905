import { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  let handleClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };
  const [activeMenuItem, setActiveMenuItem] = useState('');

  const activeStyle = {
    color: '#285b69',
    fontWeight: 'bold'
  };

  return (
    <>
      <div className="header">
        <img className="logo" src="../logo.png" alt="logo" />
        <p
          style={{
            display: 'inline',
            fontSize: '40px',
            color: 'black',
            fontWeight: '400'
          }}
        >
          Dima Alrommo
        </p>
      </div>
      <br />
      <div className="navbar">
        <Link
          style={activeMenuItem === 'home' ? activeStyle : {}}
          onClick={handleClick.bind(this, 'home')}
          className="navbarElement underline"
          to="/"
        >
          HOME
        </Link>
        <Link
          style={activeMenuItem === 'paintings' ? activeStyle : {}}
          onClick={handleClick.bind(this, 'paintings')}
          className="navbarElement underline"
          to="/paintings"
        >
          WORKS
        </Link>

        <Link
          style={activeMenuItem === 'exhibitions' ? activeStyle : {}}
          onClick={handleClick.bind(this, 'exhibitions')}
          className="navbarElement underline"
          to="/exhibitions"
        >
          EXHIBITIONS
        </Link>
        <Link
          style={activeMenuItem === 'cv' ? activeStyle : {}}
          onClick={handleClick.bind(this, 'cv')}
          className="navbarElement underline"
          to="/cv"
        >
          CV
        </Link>
        <Link
          style={activeMenuItem === 'contact' ? activeStyle : {}}
          onClick={handleClick.bind(this, 'contact')}
          className="navbarElement underline"
          to="/contact"
        >
          CONTACT
        </Link>
      </div>
    </>
  );
};

export default Navbar;
