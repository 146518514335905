import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

function FormToSend({isDesktop}) {
  const form = useRef();
  const [msg, setMsg] = useState('')
  const hideMsg = () => {
    setMsg('');
  };

  const showThenHideMsg = (msg) => {
    setTimeout(hideMsg, 5000);
    setMsg(msg);
  };
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_qe6te5i',
        'template_wqsum7r',
        form.current,
        '5H_SGG3BKp9CFhmYA'
      )
      .then(
        (result) => {
          showThenHideMsg('Message sent successfully');
        },
        (error) => {
          showThenHideMsg('Something went wrong!');
        }
      );
    e.target.reset();
  };
  return (
    <form ref={form} onSubmit={sendEmail}>
      <input className={isDesktop? 'input':'input-mobile'} type="text" placeholder="Full Name*" name="user_name" required />
      <input className={isDesktop? 'input':'input-mobile'} type="email" placeholder="Your Email*" name="user_email" required />
      <input className={isDesktop? 'input':'input-mobile'}  type="text" placeholder="Subject*" name="subject" required />
      <textarea className={isDesktop? 'textarea':'textarea-mobile'} 
        name="message"
        cols={50}
        rows={10}
        placeholder="Write something*.."
      />
      <button className={isDesktop? 'button':'button button-mobile'}  type="submit">
        Send Message
      </button>
      <p id={msg===''?'':'msg'}>{msg}</p>
      <p style={{fontSize:'12px', fontWeight:'bold'}}>Code & Design: <a className="underline" href="https://www.instagram.com/soul.of.coding/">
          @MuaazAtaya
        </a></p>
    </form>
  );
}

export default FormToSend;
