import React, { useState } from 'react';
import ImgElement from '../components/ImgElement';

const WorksPaper = () => {
  const onClickImg = (e) => {
    console.log(e.target.id);
    console.log(document.getElementsByClassName('lightbox')[0]);
    const fullPage = document.getElementsByClassName('lightbox')[0];
    fullPage.style.backgroundImage =
      'url(' + process.env.PUBLIC_URL + '/works/' + e.target.id + '.jpg)';
    fullPage.style.opacity = '100%';
    fullPage.style.display = 'block';
  };
  const hideImg = () => {
    const fullPage = document.getElementsByClassName('lightbox')[0];
    fullPage.style.display = 'none';
    fullPage.style.opacity = '0';
  };
  return (
    <>
      <div onClick={hideImg} className="lightbox" />
      <div>
        <h1>Here is works on paper.</h1>
        <div className="works-paper-img-gallery"></div>
      </div>
    </>
  );
};

export default WorksPaper;
