import logo from './logo.svg';
import './App.css';
import './App.sass';
import Navbar from './components/Navbar';
import MobileNavbar from './components/MobileNavbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import WorksPaper from './pages/WorksPaper';
import Paintings from './pages/Paintings';
import Exhibitions from './pages/Exhibitions';
import Footer from './components/Footer';
import { useMediaQuery } from 'react-responsive';
import Contact from './pages/Contact';
import CV from './pages/CV';

function App() {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1000px)'
  });
  //const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1000px)'
  });
  //const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  let onClickImg = (img) => {
    const fullPage = document.getElementsByClassName('lightbox')[0];
    fullPage.style.backgroundImage = 'url(' + img + ')';
    fullPage.style.opacity = '1';
    fullPage.style.display = 'block';
  };
  const hideImg = () => {
    const fullPage = document.getElementsByClassName('lightbox')[0];
    fullPage.style.opacity = '0';
    fullPage.style.display = 'none';
  };
  return (
    <div className="App">
      <div onClick={hideImg} className="lightbox" />

      <Router>
        {isDesktop ? <Navbar /> : <MobileNavbar />}
        <Routes>
          <Route
            exact
            path="/"
            element={<Home onClickImg={onClickImg} isDesktop={isDesktop} />}
          />
          <Route
            path="/paintings"
            element={
              <Paintings onClickImg={onClickImg} isDesktop={isDesktop} />
            }
          />
          <Route path="/works-paper" element={<WorksPaper />} />
          <Route
            path="/exhibitions"
            element={<Exhibitions isDesktop={isDesktop} />}
          />
          <Route path="/cv" element={<CV isDesktop={isDesktop} />} />
          <Route path="/contact" element={<Contact isDesktop={isDesktop} />} />
        </Routes>
      </Router>
      <Footer isDesktop={isDesktop} />
    </div>
  );
}

export default App;
