import React from 'react';
import ImgElement from '../components/ImgElement';
import poster from '../asset/poster.png';

const Home = ({ onClickImg, isDesktop }) => {
  return (
    <div className="home">
      <img src="../home.jpeg" alt="home" />
      {/*!isDesktop && (
        <iframe
          src="https://www.youtube.com/shorts/FPJi_MkJs4Q"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
          title="video"
        />
      )*/}
      {/*!isDesktop && (
        <>
          <h5
            style={{
              color: '#563D56',
              backgroundColor: 'rgba(40, 105, 102, 0.25)',
              textAlign: 'center',
              padding: '10px'
            }}
          >
            News
          </h5>
          <div style={{ padding: '0 30px' }} className="grid-works-mobile">
            <ImgElement
              addClassName={'img-element-mobile width-70'}
              src={poster}
              onClickImg={onClickImg}
            />
            <p
              style={{
                alignSelf: 'center',
                fontSize: 'small',
                padding: '0 5px',
                color: '#563D56'
              }}
            >
              SPACES Exhibition soon.
              <br />
              13-15.01.2023
              <br />
              20-23.01.23
              <br />
              15:00 bis 20:00 Uhr.
            </p>
          </div>{' '}
        </>
            )*/}
    </div>
  );
};

export default Home;
