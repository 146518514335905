import { SocialIcon } from 'react-social-icons';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Footer = ({ isDesktop }) => {
  const form = useRef();
  const [msg, setMsg] = useState('');
  const hideMsg = () => {
    setMsg('');
  };

  const showThenHideMsg = (msg) => {
    setTimeout(hideMsg, 5000);
    setMsg(msg);
  };
  const sendEmail = (e) => {
    const validateEmail = (email) => {
      // Regular expression for basic email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };

    e.preventDefault();
    if (!validateEmail) {
      showThenHideMsg('Invalid Email');
      return;
    }

    emailjs
      .sendForm(
        'service_qe6te5i',
        'template_1oq3cis',
        form.current,
        '5H_SGG3BKp9CFhmYA'
      )
      .then(
        (result) => {
          showThenHideMsg('Signed Up successfully');
        },
        (error) => {
          showThenHideMsg('Something went wrong!');
        }
      );
    e.target.reset();
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#28696640',
          minHeight: '100px',
          marginBottom: '50px',
          marginTop: '50px',
          color: 'black',
          fontSize: '2rem'
        }}
      >
        <form style={{ margin: '30px 0' }} ref={form} onSubmit={sendEmail}>
          <input
            className={isDesktop ? 'input' : 'input-mobile'}
            type="email"
            placeholder="Your Email to get newsletter*"
            name="user_email"
            required
            style={{ margin: '0 auto', minWidth: '195px' }}
          />

          <button
            className={isDesktop ? 'button' : 'button button-mobile'}
            type="submit"
          >
            Signup to newsletter
          </button>
          <p id={msg === '' ? '' : 'msg'}>{msg}</p>
        </form>
      </div>

      <div className="row">
        <div style={{ backgroundColor: 'transparent', color: '#563D56' }}>
          <h6>Dima Alrommo &copy; </h6>
        </div>
      </div>
      <div style={{ backgroundColor: '#fff', paddingBottom: '3%' }}>
        <SocialIcon
          style={{ margin: '5px' }}
          url="https://www.instagram.com/dimaalrommo/"
        />
        <SocialIcon
          style={{ margin: '5px' }}
          url="https://www.youtube.com/channel/UCZ3UOkdtdHwgHX3hD68EPRQ/featured"
        />
      </div>
    </>
  );
};

export default Footer;
