import React from 'react';
import ImgElement from '../components/ImgElement';
import img1 from '../asset/1.png';
import img2 from '../asset/2.png';
import img3 from '../asset/3.png';
import img4 from '../asset/4.png';
import img5 from '../asset/5.png';
import img6 from '../asset/6.png';
import img7 from '../asset/7.png';

import papierImg0 from '../asset/papier/0.jpg';
import papierImg1 from '../asset/papier/1.jpg';
import papierImg3 from '../asset/papier/3.jpg';
import papierImg4 from '../asset/papier/4.jpg';
import papierImg5 from '../asset/papier/5.jpg';
import papierImg6 from '../asset/papier/6.jpg';
import papierImg7 from '../asset/papier/7.jpg';
import papierImg8 from '../asset/papier/8.png';
import papierImg9 from '../asset/papier/9.jpg';
import papierImg10 from '../asset/papier/10.jpg';
import papierImg11 from '../asset/papier/11.jpg';
import papierImg12 from '../asset/papier/12.png';
import papierImg13 from '../asset/papier/13.png';
import papierImg14 from '../asset/papier/14.png';
import papierImg15 from '../asset/papier/15.png';
import papierImg16 from '../asset/papier/16.jpg';
import papierImg17 from '../asset/papier/17.jpg';
import papierImg18 from '../asset/papier/18.jpg';
import papierImg19 from '../asset/papier/19.jpg';
import papierImg20 from '../asset/papier/20.jpg';
import papierImg21 from '../asset/papier/21.jpg';
import papierImg22 from '../asset/papier/22.jpg';
import papierImg23 from '../asset/papier/23.jpg';
import papierImg24 from '../asset/papier/24.png';
import papierImg26 from '../asset/papier/26.jpeg';

import openStudioImg0 from '../asset/Openstudio23/0.png';
import openStudioImg1 from '../asset/Openstudio23/1.png';
import openStudioImg2 from '../asset/Openstudio23/2.png';
import openStudioImg3 from '../asset/Openstudio23/3.png';

import paintingsOnCanvasImg0 from '../asset/paintingsOnCanvas/0.jpg';
import paintingsOnCanvasImg1 from '../asset/paintingsOnCanvas/1.jpg';
import paintingsOnCanvasImg2 from '../asset/paintingsOnCanvas/2.jpg';
import paintingsOnCanvasImg3 from '../asset/paintingsOnCanvas/3.jpeg';
import paintingsOnCanvasImg4 from '../asset/paintingsOnCanvas/4.jpeg';
import paintingsOnCanvasImg5 from '../asset/paintingsOnCanvas/5.jpeg';
import paintingsOnCanvasImg6 from '../asset/paintingsOnCanvas/6.jpeg';
import paintingsOnCanvasImg7 from '../asset/paintingsOnCanvas/7.jpeg';
import paintingsOnCanvasImg8 from '../asset/paintingsOnCanvas/8.jpeg';
import paintingsOnCanvasImg9 from '../asset/paintingsOnCanvas/9.jpeg';
import paintingsOnCanvasImg10 from '../asset/paintingsOnCanvas/10.jpeg';
import paintingsOnCanvasImg11 from '../asset/paintingsOnCanvas/11.jpeg';
import paintingsOnCanvasImg12 from '../asset/paintingsOnCanvas/12.jpeg';
import paintingsOnCanvasImg13 from '../asset/paintingsOnCanvas/13.jpeg';
import paintingsOnCanvasImg14 from '../asset/paintingsOnCanvas/14.jpeg';
import paintingsOnCanvasImg15 from '../asset/paintingsOnCanvas/15.jpeg';
import paintingsOnCanvasImg16 from '../asset/paintingsOnCanvas/16.jpeg';
import paintingsOnCanvasImg17 from '../asset/paintingsOnCanvas/17.jpeg';
import paintingsOnCanvasImg18 from '../asset/paintingsOnCanvas/18.jpeg';
import paintingsOnCanvasImg19 from '../asset/paintingsOnCanvas/19.jpeg';
import paintingsOnCanvasImg20 from '../asset/paintingsOnCanvas/20.jpeg';
import paintingsOnCanvasImg21 from '../asset/paintingsOnCanvas/21.jpeg';
import paintingsOnCanvasImg22 from '../asset/paintingsOnCanvas/22.jpeg';
import paintingsOnCanvasImg23 from '../asset/paintingsOnCanvas/23.jpeg';
import paintingsOnCanvasImg24 from '../asset/paintingsOnCanvas/24.jpeg';
import paintingsOnCanvasImg25 from '../asset/paintingsOnCanvas/25.jpeg';
import paintingsOnCanvasImg26 from '../asset/paintingsOnCanvas/26.jpeg';
import paintingsOnCanvasImg27 from '../asset/paintingsOnCanvas/27.jpeg';
import paintingsOnCanvasImg28 from '../asset/paintingsOnCanvas/28.jpeg';
import paintingsOnCanvasImg29 from '../asset/paintingsOnCanvas/29.jpeg';
import paintingsOnCanvasImg30 from '../asset/paintingsOnCanvas/30.jpg';

import spacesImg1 from '../asset/spaces/spaces_1.jpeg';
import spacesImg2 from '../asset/spaces/spaces_2.jpeg';
import spacesImg3 from '../asset/spaces/spaces_3.jpeg';
import spacesImg4 from '../asset/spaces/spaces_4.jpeg';
import spacesImg5 from '../asset/spaces/spaces_5.jpeg';
import spacesImg6 from '../asset/spaces/spaces_6.jpeg';

import paperWindowImg1 from '../asset/paper_2023/paper_01.jpeg';
import paperWindowImg2 from '../asset/paper_2023/paper_02.jpeg';
import paperWindowImg3 from '../asset/paper_2023/paper_03.jpeg';
import paperWindowImg4 from '../asset/paper_2023/paper_04.jpeg';
import paperWindowImg5 from '../asset/paper_2023/paper_05.jpeg';

const Paintings = ({ onClickImg, isDesktop }) => {
  return (
    <>
      {isDesktop && (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#28696640',
              minHeight: '100px',
              marginBottom: '50px',
              marginTop: '50px',
              color: 'black',
              fontSize: '2rem'
            }}
          >
            Paintings on Canvas
          </div>
          <ImgElement
            addClassName={'img-element width-60'}
            src={paintingsOnCanvasImg30}
            onClickImg={onClickImg}
          />
          <div className="outer">
            <div className="left-pane">
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg24}
                onClickImg={onClickImg}
              />
            </div>
            <div className="right-pane grid-works">
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg25}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg28}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg29}
                onClickImg={onClickImg}
              />
            </div>
          </div>

          <div className="outer">
            <div className="left-pane">
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg22}
                onClickImg={onClickImg}
              />
            </div>
            <div className="right-pane grid-works">
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg1}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg7}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg26}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg27}
                onClickImg={onClickImg}
              />

              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg5}
                onClickImg={onClickImg}
              />

              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg23}
                onClickImg={onClickImg}
              />
            </div>
          </div>

          <div className="outer">
            <div className="left-pane">
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg18}
                onClickImg={onClickImg}
              />
            </div>
            <div className="right-pane grid-works">
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg8}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg9}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg10}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg11}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg12}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg19}
                onClickImg={onClickImg}
              />
            </div>
          </div>

          <div className="outer">
            <div className="left-pane">
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg0}
                onClickImg={onClickImg}
              />
            </div>
            <div className="right-pane grid-works">
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg15}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg16}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg2}
                onClickImg={onClickImg}
              />

              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg21}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg14}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={paintingsOnCanvasImg17}
                onClickImg={onClickImg}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#28696640',
              minHeight: '100px',
              marginBottom: '50px',
              marginTop: '50px',
              color: 'black',
              fontSize: '2rem'
            }}
          >
            Open Studio 2023
          </div>

          <div className="outer">
            <div className="left-pane">
              <ImgElement
                addClassName={'img-element'}
                src={openStudioImg0}
                onClickImg={onClickImg}
              />
            </div>
            <div className="right-pane grid-works">
              <ImgElement
                addClassName={'img-element'}
                src={openStudioImg1}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={openStudioImg2}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={openStudioImg3}
                onClickImg={onClickImg}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#28696640',
              minHeight: '100px',
              marginBottom: '50px',
              marginTop: '50px',
              color: 'black',
              fontSize: '2rem'
            }}
          >
            Spaces
          </div>

          <div className="outer">
            <div className="left-pane">
              <ImgElement
                addClassName={'img-element'}
                src={spacesImg3}
                onClickImg={onClickImg}
              />
            </div>
            <div className="right-pane grid-works-2">
              <ImgElement
                addClassName={'img-element'}
                src={spacesImg2}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={spacesImg5}
                onClickImg={onClickImg}
              />
            </div>
          </div>
          <div className="outer">
            <div className="left-pane">
              <ImgElement
                addClassName={'img-element'}
                src={spacesImg1}
                onClickImg={onClickImg}
              />
            </div>

            <div className="right-pane grid-works-2">
              <ImgElement
                addClassName={'img-element'}
                src={spacesImg6}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={spacesImg4}
                onClickImg={onClickImg}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#28696640',
              minHeight: '100px',
              marginBottom: '50px',
              marginTop: '50px',
              color: 'black',
              fontSize: '2rem'
            }}
          >
            Paper
          </div>
          <div style={{ margin: '1% 7%' }} className="right-pane grid-works-4">
            <ImgElement
              addClassName={'img-element'}
              src={paperWindowImg1}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element'}
              src={paperWindowImg2}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element'}
              src={paperWindowImg3}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element'}
              src={paperWindowImg4}
              onClickImg={onClickImg}
            />
          </div>
          <div className="outer">
            <div className="left-pane">
              <ImgElement
                addClassName={'img-element'}
                src={papierImg14}
                onClickImg={onClickImg}
              />
            </div>
            <div className="right-pane grid-works">
              <ImgElement
                addClassName={'img-element'}
                src={papierImg11}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={papierImg9}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={papierImg10}
                onClickImg={onClickImg}
              />

              <ImgElement
                addClassName={'img-element'}
                src={papierImg12}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={papierImg6}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={papierImg21}
                onClickImg={onClickImg}
              />
            </div>
          </div>

          <div className="outer">
            <div className="left-pane">
              <ImgElement
                addClassName={'img-element'}
                src={papierImg15}
                onClickImg={onClickImg}
              />
            </div>
            <div className="right-pane grid-works">
              <ImgElement
                addClassName={'img-element'}
                src={papierImg22}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={papierImg5}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={papierImg23}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={papierImg19}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={papierImg8}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={papierImg24}
                onClickImg={onClickImg}
              />
            </div>
          </div>

          <div className="outer">
            <div className="left-pane">
              <ImgElement
                addClassName={'img-element'}
                src={papierImg13}
                onClickImg={onClickImg}
              />
            </div>
            <div className="right-pane grid-works">
              <ImgElement
                addClassName={'img-element'}
                src={papierImg0}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={papierImg1}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={papierImg3}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={papierImg4}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={papierImg16}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={papierImg17}
                onClickImg={onClickImg}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#28696640',
              minHeight: '100px',
              marginBottom: '50px',
              marginTop: '50px',
              color: 'black',
              fontSize: '2rem'
            }}
          >
            Paintings On Wood
          </div>

          <div className="outer">
            <div className="left-pane">
              <ImgElement
                addClassName={'img-element'}
                src={img1}
                onClickImg={onClickImg}
              />
            </div>
            <div className="right-pane grid-works">
              <ImgElement
                addClassName={'img-element'}
                src={img3}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={img5}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={img4}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={img2}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={img6}
                onClickImg={onClickImg}
              />
              <ImgElement
                addClassName={'img-element'}
                src={img7}
                onClickImg={onClickImg}
              />
            </div>
          </div>
        </>
      )}

      {!isDesktop && (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#28696640',
              minHeight: '100px',
              marginBottom: '50px',
              marginTop: '50px',
              color: 'black',
              fontSize: '2rem'
            }}
          >
            Paintings on Canvas
          </div>
          <ImgElement
            addClassName={'img-element-mobile'}
            src={paintingsOnCanvasImg30}
            onClickImg={onClickImg}
          />
          <ImgElement
            addClassName={'img-element-mobile'}
            src={paintingsOnCanvasImg29}
            onClickImg={onClickImg}
          />
          <div className="grid-works-mobile">
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg25}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg26}
              onClickImg={onClickImg}
            />
          </div>

          <ImgElement
            addClassName={'img-element-mobile'}
            src={paintingsOnCanvasImg22}
            onClickImg={onClickImg}
          />
          <ImgElement
            addClassName={'img-element-mobile'}
            src={paintingsOnCanvasImg24}
            onClickImg={onClickImg}
          />

          <div className="grid-works-mobile">
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg5}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg1}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg7}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg23}
              onClickImg={onClickImg}
            />
          </div>

          <ImgElement
            addClassName={'img-element-mobile'}
            src={paintingsOnCanvasImg18}
            onClickImg={onClickImg}
          />
          <ImgElement
            addClassName={'img-element-mobile'}
            src={paintingsOnCanvasImg27}
            onClickImg={onClickImg}
          />

          <div className="grid-works-mobile">
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg8}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg9}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg10}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg11}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg12}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg19}
              onClickImg={onClickImg}
            />
          </div>

          <ImgElement
            addClassName={'img-element-mobile'}
            src={paintingsOnCanvasImg28}
            onClickImg={onClickImg}
          />
          <div className="grid-works-mobile">
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg15}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg16}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg2}
              onClickImg={onClickImg}
            />

            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg21}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg14}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paintingsOnCanvasImg17}
              onClickImg={onClickImg}
            />
          </div>

          <ImgElement
            addClassName={'img-element-mobile'}
            src={paintingsOnCanvasImg0}
            onClickImg={onClickImg}
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#28696640',
              minHeight: '100px',
              marginBottom: '50px',
              marginTop: '50px',
              color: 'black',
              fontSize: '2rem'
            }}
          >
            Open Studio 2023
          </div>

          <div>
            <ImgElement
              addClassName={'img-element-mobile'}
              src={openStudioImg0}
              onClickImg={onClickImg}
            />
          </div>

          <div className="grid-works-mobile">
            <ImgElement
              addClassName={'img-element-mobile'}
              src={openStudioImg1}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={openStudioImg2}
              onClickImg={onClickImg}
            />
          </div>
          <ImgElement
            addClassName={'img-element-mobile'}
            src={openStudioImg3}
            onClickImg={onClickImg}
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#28696640',
              minHeight: '100px',
              marginBottom: '50px',
              marginTop: '50px',
              color: 'black',
              fontSize: '2rem'
            }}
          >
            Spaces
          </div>
          <ImgElement
            addClassName={'img-element-mobile'}
            src={spacesImg1}
            onClickImg={onClickImg}
          />
          <div>
            <ImgElement
              addClassName={'img-element-mobile'}
              src={spacesImg3}
              onClickImg={onClickImg}
            />
          </div>

          <div className="grid-works-mobile">
            <ImgElement
              addClassName={'img-element-mobile'}
              src={spacesImg2}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={spacesImg5}
              onClickImg={onClickImg}
            />
          </div>
          <div className="grid-works-mobile">
            <ImgElement
              addClassName={'img-element-mobile'}
              src={spacesImg6}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={spacesImg4}
              onClickImg={onClickImg}
            />
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#28696640',
              minHeight: '100px',
              marginBottom: '50px',
              marginTop: '50px',
              color: 'black',
              fontSize: '2rem'
            }}
          >
            Paper
          </div>
          <ImgElement
            addClassName={'img-element-mobile'}
            src={paperWindowImg5}
            onClickImg={onClickImg}
          />

          <div className="grid-works-mobile">
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paperWindowImg1}
              onClickImg={onClickImg}
            />

            <ImgElement
              addClassName={'img-element-mobile'}
              src={paperWindowImg2}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={paperWindowImg3}
              onClickImg={onClickImg}
            />

            <ImgElement
              addClassName={'img-element-mobile'}
              src={paperWindowImg4}
              onClickImg={onClickImg}
            />

            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg11}
              onClickImg={onClickImg}
            />

            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg10}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg9}
              onClickImg={onClickImg}
            />

            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg12}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg6}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg21}
              onClickImg={onClickImg}
            />
          </div>
          <div>
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg14}
              onClickImg={onClickImg}
            />
          </div>

          <div className="grid-works-mobile">
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg22}
              onClickImg={onClickImg}
            />

            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg23}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg5}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg19}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg8}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg24}
              onClickImg={onClickImg}
            />
          </div>
          <div>
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg15}
              onClickImg={onClickImg}
            />
          </div>

          <div className="grid-works-mobile">
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg0}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg1}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg3}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg4}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg16}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg17}
              onClickImg={onClickImg}
            />
          </div>
          <div>
            <ImgElement
              addClassName={'img-element-mobile'}
              src={papierImg13}
              onClickImg={onClickImg}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#28696640',
              minHeight: '100px',
              marginBottom: '50px',
              marginTop: '50px',
              color: 'black',
              fontSize: '2rem'
            }}
          >
            Paintings On Wood
          </div>

          <div className="grid-works-mobile">
            <ImgElement
              addClassName={'img-element-mobile'}
              src={img2}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={img3}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={img4}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={img5}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={img6}
              onClickImg={onClickImg}
            />
            <ImgElement
              addClassName={'img-element-mobile'}
              src={img7}
              onClickImg={onClickImg}
            />
          </div>
          <div>
            <ImgElement
              addClassName={'img-element-mobile'}
              src={img1}
              onClickImg={onClickImg}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Paintings;
