import React from 'react';
import FormToSend from './FormToSend';

const Contact = ({ isDesktop }) => {
  return (
    <div className={isDesktop ? 'settings' : 'settings-mobile'}>
      <p>
        Feel free to contact me via E-Mail, Instagram or directly using the Form
        below
      </p>
      <p>
        E-Mail:{' '}
        <a
          className={isDesktop ? 'underline' : ''}
          href="mailto: alrommodima@gmail.com"
        >
          alrommodima@gmail.com
        </a>
      </p>
      <p>
        Instagram:{' '}
        <a
          className={isDesktop ? 'underline' : ''}
          href="https://www.instagram.com/dimaalrommo/"
        >
          @DimaAlrommo
        </a>
      </p>
      <p>Form:</p>
      <FormToSend isDesktop={isDesktop} />
    </div>
  );
};

export default Contact;
