const ImgElement = ({ onClickImg, text = '', src, addClassName }) => {
  return (
    <button className={addClassName} onClick={onClickImg.bind(this, src)}>
      <img loading="lazy" src={src} alt={'img not loaded!'} />
      <div className="container">
        <p>{text}</p>
      </div>
    </button>
  );
};
export default ImgElement;
