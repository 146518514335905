import { Link } from 'react-router-dom';
import { useState } from 'react';

const MobileNavbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  let toggleNav = () => {
    setShowNavbar((s) => !s);
  };
  let handleClick = (menuItem) => {
    setActiveMenuItem(menuItem);
    toggleNav();
  };
  const [activeMenuItem, setActiveMenuItem] = useState('');

  const activeStyle = {
    color: 'rgb(70 160 185)',
    fontWeight: 'bold'
  };

  return (
    <>
      {showNavbar && (
        <div id="mySidebar" className="sidebar">
          <button
            style={{ width: '100%' }}
            className="openbtn"
            onClick={toggleNav}
          >
            ☰
          </button>

          <div className="navbar-mobile">
            <Link
              style={activeMenuItem === 'home' ? activeStyle : {}}
              onClick={handleClick.bind(this, 'home')}
              to="/"
            >
              HOME
            </Link>
            <Link
              style={activeMenuItem === 'paintings' ? activeStyle : {}}
              onClick={handleClick.bind(this, 'paintings')}
              to="/paintings"
            >
              WORKS
            </Link>
            <Link
              style={activeMenuItem === 'exhibitions' ? activeStyle : {}}
              onClick={handleClick.bind(this, 'exhibitions')}
              to="/exhibitions"
            >
              EXHIBITIONS
            </Link>
            <Link
              style={activeMenuItem === 'cv' ? activeStyle : {}}
              onClick={handleClick.bind(this, 'cv')}
              to="/cv"
            >
              CV
            </Link>
            <Link
              style={activeMenuItem === 'contact' ? activeStyle : {}}
              onClick={handleClick.bind(this, 'contact')}
              to="/contact"
            >
              CONTACT
            </Link>
          </div>
        </div>
      )}
      {!showNavbar && (
        <button className="openbtn" onClick={toggleNav}>
          ☰
        </button>
      )}
      <div className="header">
        <img className="logo logo-mobile" src="../logo.png" alt="logo" />
        <p
          style={{
            display: 'inline',
            fontSize: '16px',
            color: 'black',
            fontWeight: '400'
          }}
        >
          Dima Alrommo
        </p>
      </div>
      <br />
    </>
  );
};

export default MobileNavbar;
