import React from 'react';

const CV = ({ isDesktop }) => {
  return (
    <div className={isDesktop ? 'settings' : 'settings-mobile'}>
      <h2>2019 &#8211; till now | Diploma fine Art</h2>
      <p>
        Hochschule für Bildende Künste - HBK | University of Art | Braunschweig
        - Germany
      </p>
      <p>
        2023 &#8211; till now | advanced Class Prof. Antje Majewski
      </p>
      <p>
        2020 &#8211; 2023 | advanced Class Prof. Olav Chistopher Jenssen
      </p>
      <p>2019 &#8211; 2020 | initial Class Shila Khatami</p>

      <h2>2018</h2>
      <p>
        Participation in the program | Deutsch in Museum | Herzog Anton Ulrich
        Museum | Braunschweig &#8211; Germany
      </p>
      <p>
        Guest student | Hochschule für Bildende Künste - HBK | University of Art
        | Braunschweig - Germany
      </p>

      <h2>2016 - 2017 | United Arab Emirates</h2>
      <p>leading drawing - workshops for Kids | Little Garden | Kindergarten</p>
      <p>
        Participation a wall painting Project in | Little Garden | Kindergarten
      </p>
      <p>Working in shadow puppetry | Abu-Dhabi national Theatre</p>
      <p>leading kids to practice drawing with specific topics | Bait Arabi</p>

      <h2>Before 2016 | Aleppo - Syria</h2>
      <p>
        Working as psychological and social supporter for 1st and 4th grade
        displaced kids through art by drawing and discussing | Syrian society
        for social development
      </p>
      <p>
        Assistant in Organization | Aleppo international photo festivals | with
        the artist Issa Touma | Le pont Gallery
        <ul>
          <li>
            Support in researching objects of the artists participating in the
            festival.
          </li>
          <li>Support in exhibition preparations</li>
        </ul>
      </p>
      <p>
        Volunteer work with the artist Issa Touma in his „Le pont Gallery “:
        Organisation in General Workshops, which take place in the Gallery
      </p>
      <h3>
        Born 1995 in Aleppo - Syria, living and studying since 2017 in
        Braunschweig - Germany
      </h3>
    </div>
  );
};

export default CV;
